import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dashboardImage from '../images/dashboard1.png';
import exploreImage from '../images/jobs1.png';
import trackImage from '../images/userroles.gif';



const WelcomeModal = (props) => {
    const [progress, setProgress] = useState(0);
    return (
        <Modal
            isOpen={props.shouldShowModal}
            onRequestClose={props.closeModal}
            size='large'
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 30, 43, 0.5)'
                },
                content: {
                    top: '55%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                },
            }}
            contentLabel="Example Modal"
        >
            <div style={{ display: "flex", justifyContent: "space-between", width: "60vw"}}>
                <div style={{color: "white"}}>{"111"}</div>
                <div>
                    <div style={{ textAlign: "center", fontSize: "1.5em", fontWeight: "bold", marginBottom: "0px"}}>Welcome to Techire!</div>
                    <div style={{ textAlign: "center", fontSize: "1.2em", marginTop: "0px"}}>Here's a little tour of the platform to get you started</div>
                </div>
                <button style={{ marginBottom: "20px" }} onClick={props.closeModal}><CloseIcon /></button>
            </div>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: "20px"}}>
                <Tabs value={progress} onChange={(eventpointer, tab) => {setProgress(tab);}} centered>
                    <Tab label="Dashboard" />
                    <Tab label="Find Jobs Page"  />
                    <Tab label="My Applications Page"  />
                </Tabs>
            </Box>
            {progress == 0 &&
                <div>
                    <div style={{width: "50vw", overflowY: "auto", marginLeft: "5vw", height: "55vh", marginBottom: "10px"}}>
                        <img src={dashboardImage} style={{width: "45vw", marginLeft: "2.5vw", marginBottom: "10px"}}/>
                        <div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>About:</span> The dashboard is like your home base. When you login, it will show you general information regarding the state of your recruiting process.
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>Notifications:</span> This widget will show any upcoming dates/deadlines that you've added
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>User Metrics:</span> This widget will show the breakdown of your applications
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>Startup of the day:</span> To help become more in tune with emerging startups, this widget will show a new startup each day. There are great places to work aside from FAANG!
                            </div>
                            <div>
                                <span style={{fontWeight: "bold"}}>Suggested Application:</span> This widget shows an application at random that you might have not thought about
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", width: "50vw", marginLeft: "5vw"}}>
                        <div>{" "}</div>
                        <Button onClick={() => {setProgress(progress + 1);}} >Next</Button>
                    </div>
                </div>
            }
            {progress == 1 &&
                <div>
                    <div style={{width: "50vw", overflowY: "auto", marginLeft: "5vw", height: "55vh", marginBottom: "10px"}}>
                        <img src={exploreImage} style={{width: "45vw", marginLeft: "2.5vw", marginBottom: "10px"}}/>
                        <div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>About:</span> The find jobs page is where you can discover new roles. These roles are already hand selected for interns and new grads going into SWE, Data Science, Quant, etc. and are updated regularly so you can see them while they're fresh. We recommend checking this tab every day.
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>Source:</span> These roles are scraped daily from various github lists (ReaVNaiL for new grad and pittcsc for interns)
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>Links:</span> If you come across a job you don't know, you can click the Levels.fyi, Blind, and Leetcode links for reviews, salary information, and leetcode questions to help with the interview.
                            </div>
                            <div style={{marginBottom: "6px"}}>
                                <span style={{fontWeight: "bold"}}>Application Link and Add to my jobs:</span> You can click the application link to go directly to the application. If you decide you're intereted, no need to manually input the data into a spreadsheet. Just click 'Add to my jobs' and it will be automatically added to the 'My Applications' tab
                            </div>
                            <div>
                                <span style={{fontWeight: "bold"}}>Search Bar and filters:</span> If you know exacly what you're looking for, you can search by location, job title, or company. In addition, you can choose whether you want to see newest first or oldest first and whether you are looking at internship or new grad jobs
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", width: "50vw", marginLeft: "5vw"}}>
                        <Button onClick={() => {setProgress(progress - 1);}}>Previous</Button>
                        <Button onClick={() => {setProgress(progress + 1);}} >Next</Button>
                    </div>
                </div>

            }
            {progress == 2 &&
                    <div>
                        <div style={{width: "50vw", overflowY: "auto", marginLeft: "5vw", height: "55vh", marginBottom: "10px"}}>
                            <img src={trackImage} style={{width: "45vw", marginLeft: "2.5vw", marginBottom: "10px"}}/>
                            <div>
                                <div style={{marginBottom: "6px"}}>
                                    <span style={{fontWeight: "bold"}}>About:</span> This page replaces the monotony and pain of using spreadsheets to keep track of your applications. It is meant to keep track of jobs. You can drag them between categories and click them to show information for certain jobs.
                                </div>
                                <div style={{marginBottom: "6px"}}>
                                    <span style={{fontWeight: "bold"}}>Job Detail Modal:</span> You can click any job, and a modal will pop up with relevant information. In this modal, you can keep track of things like links and recruiter info.
                                </div>
                                <div style={{marginBottom: "6px"}}>
                                    <span style={{fontWeight: "bold"}}>Schedule Events:</span> For a given job, you can schedule upcoming interviews, calls, and deadlines that will show up on your notifications widget.
                                </div>
                                <div>
                                    <span style={{fontWeight: "bold"}}>Add Jobs Manually:</span> On the bottom right, you can add jobs manually. This is for if you want to track a job that doesn't appear on our Find Jobs tab.
                                </div>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", width: "50vw", marginLeft: "5vw"}}>
                            <Button onClick={() => {setProgress(progress - 1);}}>Previous</Button>
                            <Button onClick={props.closeModal}>Get Started</Button>
                        </div>
                    </div>
            }
        </Modal>);


}

export default WelcomeModal;