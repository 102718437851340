import React, {useState} from 'react';
import './../index.css';
import { Link } from "react-router-dom";
import techireimageprimarycolor from "../images/techire_logo_primary_color.png"

const NavBar = (props) => {
  return (
    <div>
       <div className="navbar" style={{ display: "flex", justifyContent: "space-between", transition: "2s"}}>
          <div style={{display: "flex"}}>
            <div className="logo" style={{ marginRight: "10px" }}>
              <Link style={{ textDecoration: 'none' }} to="/dashboard" onClick={() => {props.editSelected("dashboard")}}>
                <img src={techireimageprimarycolor} style={{width: "150px", paddingTop: "5px", paddingBottom: "5px"}}/>
              </Link>
            </div>
            <div className = {props.selected == "dashboard" ? "navbuttonselected" : "navbutton"}>
              <Link style={{ textDecoration: 'none'}} to="/dashboard" onClick={() => {props.editSelected("dashboard")}}>
                Dashboard
              </Link>
            </div>
            <div className = {props.selected == "newjobs" ? "navbuttonselected" : "navbutton"}>
              <Link style={{ textDecoration: 'none'}} to="/newjobs" onClick={() => {props.editSelected("newjobs")}}>
                Find Jobs
              </Link>
            </div>
            <div className = {props.selected == "myjobs" ? "navbuttonselected" : "navbutton"} >
              <Link style={{ textDecoration: 'none'}} to="/myjobs" onClick={() => {props.editSelected("myjobs")}}>
                My Applications
              </Link>
            </div>
            {/* <div className = {props.selected == "calendar" ? "navbuttonselected" : "navbutton"} >
              <Link style={{ textDecoration: 'none'}} to="/calendar" onClick={() => {props.editSelected("calendar")}}>
                Calendar
              </Link>
            </div> */}
          </div>
          <div style={{display: "flex"}}>
            <div style={{ marginRight: "50px", color: "grey"}} >
              <div style={{ marginTop: "10px", color: "#447988", fontWeight: props.selected == "profile" ? "bold":"normal"}} >
                  {props.user != null && 
                    <div style={{display: "flex"}}>
                      <img src={props.user.picture} style={{borderRadius: "50%", width: "33px", height: "33px", marginTop: "-5px", marginRight: "10px"}}/>
                      <div>{props.user.name}</div>
                    </div>
                  }
              </div>
            </div>
            <div className="logout">
              <button onClick={() => { props.logout() }}>logout</button>
            </div>
          </div>
        </div>
    </div>
  );
}

export default NavBar;


