import React, { Component } from 'react';
import notifications from '../mockdata/notifications.json';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap-icons/font/bootstrap-icons.css'
import RecruitingWidget from './RecruitingWidget';
import NotificationsWidget from './NotificationsWidget';
import UpcomingWidget from './UpcomingWidget';
import UserMetricsWidget from './UserMetrics/UserMetricsWidget';
import WelcomeModal from './welcomemodal';
import ProjectsWidget from './ProjectsWidget';
import SuggestedApplicationsWidget from './SuggestedApplicationsWidget';
import Button from '@mui/material/Button';
import { SketchPicker } from 'react-color';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import StartupWidget from './StartupWidget';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import firebase from 'firebase/compat/app';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class LandingPage extends Component {

    constructor() {
        super();
        /* add new booleans for each widget */
        this.state = {
            displayNotifications: true,
            displayUpcoming: true,
            displayUserMetrics: true,
            displayProjects: true,
            messageStatus: false,
            displayRecruiting: false,
            displayStartup: true,
            displaySuggestedApps: true
        };
    }

    closeModal = () => {
        this.props.setNewUser(false);
    }

    checkNumberOfWidgets = () => {
        let curr = 0;
        if (this.state.displayNotifications) curr++;
        if (this.state.displayUpcoming) curr++;
        if (this.state.displayUserMetrics) curr++;
        if (this.state.displayProjects) curr++;
        if (this.state.displayRecruiting) curr++;
        if (this.state.displayStartup) curr++;
        if (this.state.displaySuggestedApps) curr++;
        if (curr == 6) return false;
        return true;
    }

    handleBackgroundColorChange = (color, e) => {
        this.setState({ screenColor: color.hex })
    }

    handleAddNotificationClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayNotifications) {
            this.setState({ displayNotifications: !this.state.displayNotifications })
        } else {
            this.open();
        }
    }

    handleAddUpcomingClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayUpcoming) {
            this.setState({ displayUpcoming: !this.state.displayUpcoming })
        } else {
            this.open();
        }
    }

    handleAddRecruitingClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayRecruiting) {
            this.setState({ displayRecruiting: !this.state.displayRecruiting })
        } else {
            this.open();
        }
    }

    handleAddUserMetrics = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayUserMetrics) {
            this.setState({ displayUserMetrics: !this.state.displayUserMetrics })
        } else {
            this.open();
        }
    }

    handleAddProjectClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayProjects) {
            this.setState({ displayProjects: !this.state.displayProjects })
        } else {
            this.open();
        }
    }

    handleAddSuggestedAppsClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displaySuggestedApps) {
            this.setState({ displaySuggestedApps: !this.state.displaySuggestedApps })
        } else {
            this.open();
        }
    }

    handleAddStartupClick = (e) => {
        if (this.checkNumberOfWidgets() || this.state.displayStartup) {
            this.setState({ displayStartup: !this.state.displayStartup })
        } else {
            this.open();
        }
    }

    handleModalClose = (e) => {
        this.setState({ colorModalOpen: !this.state.colorModalOpen })
    }

    handleChangeBackgroundBottomColor = (color, e) => {
        this.setState({ gradientColorBottom: color.hex })
    }

    handleChangeBackgroundTopColor = (color, e) => {
        this.setState({ gradientColorTop: color.hex })
    }

    open = () => {
        this.setState({ messageStatus: true });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ messageStatus: false });
    }

    handleSketchPickerState = (e) => {
        this.setState({ sketchPickerOneOpen: !this.state.sketchPickerOneOpen })
        this.setState({ sketchPickerTwoOpen: !this.state.sketchPickerTwoOpen })
    }

    render() {
        console.log(this.props.newUser);
        return (
            <div className='page-background'
                style={{
                    width: "100%",
                    height: "150%",
                    marginTop: "90px",
                    background: "linear-gradient(to right top," + this.state.gradientColorBottom + "," + this.state.gradientColorTop + ")",
                }}
            >
                <WelcomeModal shouldShowModal={this.props.newUser} closeModal={this.closeModal} />
                <div className='widget-container'>
                    <div>
                        <UpcomingWidget />
                        {this.props.user != null && <UserMetricsWidget user={this.props.user} apiURL={this.props.apiURL}/>}
                    </div>
                    <div>
                        {this.props.user != null && <NotificationsWidget user={this.props.user} apiURL={this.props.apiURL}/>}
                        {this.props.user != null && <SuggestedApplicationsWidget user={this.props.user} apiURL={this.props.apiURL}/>}
                    </div>
                    <div>
                        <StartupWidget apiURL={this.props.apiURL}/>
                    </div>
                </div>
            </div>
        );

    }
}
