import './App.css';
import { Navigate } from "react-router-dom";
import NavBar from './components/navigationbar.js'
import MyJobs from './components/myjobs.js'
import NewJobs from './components/newjobs.js'
import LandingPage from './components/landingpage';
import axios from "axios";
import { supabase } from './client';
import Login from './components/loginsignup';
import Profile from './components/profile';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";


const API_URL="https://techire-api.onrender.com";
// const API_URL="http://localhost:8080"


const App = () => {

  const [user, editUser] = useState(null);
  const [selected, editSelected] = useState("dashboard");
  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    checkUser();
    window.addEventListener('hashchange', function() {
      checkUser();
    });
  }, []);

  const checkUser = async () => {
    const user = await supabase.auth.getUser();
    console.log(user);
    if (user.error == null && user.data != null) {
      const userObj = {
        name: user.data.user.user_metadata.name,
        email: user.data.user.user_metadata.email,
        picture: user.data.user.user_metadata.avatar_url
      }
      editUser(userObj);
      axios.post(`${API_URL}/register`, userObj).then(response => {
        if (response.status === 200) {
          setNewUser(true);
        }
      })
    }
  }

  const signInWithGithub = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'github',
    })
  }

  const signInWithGoogle = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    })
  }

  const signInWithLinkedin = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'linkedin',
    });
  }

  const logout = async () => {
    await supabase.auth.signOut();
    editUser(null);
  }

    return (
      <BrowserRouter>
        {user != null && <NavBar user={user} logout={logout} editSelected={editSelected} selected={selected}/>}
        <Routes>
          <Route path='/' exact element={ user != null ? <Navigate to="/dashboard"/> : <Login apiURL={API_URL} editUser={editUser} user={user} logout={logout} signInWithGithub={signInWithGithub} signInWithGoogle={signInWithGoogle} signInWithLinkedin={signInWithLinkedin} />}/>
          <Route path='/dashboard' exact element={ user != null ? <LandingPage user={user.email} newUser={newUser} setNewUser={setNewUser} apiURL={API_URL}/> : <Navigate to="/" />} />
          <Route path='/myjobs' element={ user != null ? <MyJobs  user={user.email} apiURL={API_URL}/> : <Navigate to="/" />} />
          <Route path='/newjobs' element={ user != null ? <NewJobs  user={user.email} apiURL={API_URL}/> : <Navigate to="/" />} />
          {/* <Route path='/calendar' element={ user != null ? <Calendar user={user.id} apiURL={API_URL}/> : <Navigate to="/" />} /> */}
          <Route path='/profile' element={ user != null ? <Profile user={user.email} /> : <Navigate to="/" />}  />
        </Routes>
      </BrowserRouter>
    );
}

export default App;